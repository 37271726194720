import { ApiOutlined, CommentOutlined, FormOutlined, HddOutlined, MenuOutlined, OrderedListOutlined, PictureOutlined, RobotOutlined, SearchOutlined, SoundOutlined, SwapOutlined, TableOutlined, TeamOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Dropdown, Popover } from "antd";
import { useMainContext } from "../Context/MainContext";
import { useAuth } from "../../utils/useAuth";
import { Link } from "react-router-dom";
import { useEventTracker } from "../Context/EventTrackingContext";


function MainNavbar() {
    const {user} = useAuth();
    const {setIsMobileMenuDrawerVisible} = useMainContext();
    const {sendEvent} = useEventTracker();

    const typeItems = [
        {
            key:'1',
            label:(
                <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Type - Text Vector'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Text Vectors <FormOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Create Queryable Spreadsheets and easily visualize text tables, embeddings and parse text data.
                    </p>
                </div>
            )
        },
        {
            key:'2',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Type - Image Vector'})}>
                    <div className="flex items-center w-full mb-2">
                        <div className="flex-1 text-slate-900">
                            <h4 className="m-0 leading-none font-semibold">Image Vectors <PictureOutlined className="text-ecalmers-full" /></h4>
                        </div>
                        <div className="text-slate-400 text-xs">
                            Coming Soon
                        </div>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Search Images, Build Recommedation Systems, all-in-one intuitive interface.
                    </p>
                </div>
            )
        },
        {
            key:'3',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Type - Audio Vector'})}>
                    <div className="flex items-center w-full mb-2">
                        <div className="flex-1 text-slate-900">
                            <h4 className="m-0 leading-none font-semibold">Audio Vectors <SoundOutlined className="text-ecalmers-full" /></h4>
                        </div>
                        <div className="text-slate-400 text-xs">
                            Coming Soon
                        </div>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Search Audio, Build Recommendation Systems, Build Assistants. All-in-one.
                    </p>
                </div>
            )
        },
        {
            key:'4',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Type - Video Vector'})}>
                    <div className="flex items-center w-full mb-2">
                        <div className="flex-1 text-slate-900">
                            <h4 className="m-0 leading-none font-semibold">Video Vectors <VideoCameraOutlined className="text-ecalmers-full" /></h4>
                        </div>
                        <div className="text-slate-400 text-xs">
                            Coming Soon
                        </div>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Search Video, Build Recommendation Systems, Build Assistants. All-in-one.
                    </p>
                </div>
            )
        },
    ];

    const featureItems = [
        {
            key:'1',
            label:(
                <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Feature - Storage'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Scalable Vector Storage <HddOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Easily store millions of vectors with high availability and reliability.
                    </p>
                </div>
            )
        },
        {
            key:'2',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Feature - Search'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Simple Vector Search <SearchOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Perform rapid vector searches with optimized indexing for real-time results. Select from different models to find the best fit your applications.
                    </p>
                </div>
            )
        },
        {
            key:'3',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Feature - Table'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Interactive Table View <TableOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Manage your embeddings as easily as an Excel sheet. Import new data and update your embeddings instantly.
                    </p>
                </div>
            )
        },
        {
            key:'4',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Feature - API'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">No-Code API <ApiOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Create API endpoints to interact with third party apps all without a single line of code.
                    </p>
                </div>
            )
        },
    ];

    const applicationItems = [
        {
            key:'1',
            label:(
                <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - LLM'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">LLM Context <CommentOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Provide your LLMs with relavant information with a simple search on any of your tables.
                    </p>
                </div>
            )
        },
        {
            key:'2',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - ML/AI'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">AI & Machine Learning <RobotOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Optimize your ML models with fast vector search and retrieval.
                    </p>
                </div>
            )
        },
        {
            key:'3',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - Recommendation System'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Recommendation Systems <OrderedListOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Power personalized recommendations by matching user preferences with vector embeddings.
                    </p>
                </div>
            )
        },
        {
            key:'4',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - Similarity'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Content Similarity <SwapOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Identify and recommend similar content with efficient vector comparison.
                    </p>
                </div>
            )
        },
        {
            key:'5',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - Search'})}>
                    <div className="mb-2 text-slate-900">
                        <h4 className="m-0 leading-none font-semibold">Image & Text Embedding Search <SearchOutlined className="text-ecalmers-full" /></h4>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Effortlessly search through large datasets of images and text.
                    </p>
                </div>
            )
        },
    ];

    const pricingItems = [
        {
            key:'1',
            label:(
                <div className="p-2 py-3 pt-4" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Pricing - Pro'})}>
                    <div className="flex items-center w-full mb-2">
                        <div className="flex-1 text-slate-900">
                            <h4 className="m-0 leading-none font-semibold">Pro Tier <TeamOutlined className="text-ecalmers-full" /></h4>
                        </div>
                        <div className="text-slate-500 text-xs">
                            $49/month
                        </div>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Up to 1M vectors, Priority support, Full API access.
                    </p>
                </div>
            )
        },
        {
            key:'2',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - Free'})}>
                    <div className="flex items-center w-full mb-2">
                        <div className="flex-1 text-slate-900">
                            <h4 className="m-0 leading-none font-semibold">Free Tier <UserOutlined className="text-ecalmers-full" /></h4>
                        </div>
                        <div className="text-slate-500 text-xs">
                            FREE
                        </div>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Up to 10,000 vectors, Basic support, Limited API access.
                    </p>
                </div>
            )
        },
        {
            key:'3',
            label:(
                <div className="p-2 py-3" style={{maxWidth:'350px'}} onClick={() => sendEvent({category:'Dropdown Item', action:'Click', label:'Apps - Enterprise'})}>
                    <div className="flex items-center w-full mb-2">
                        <div className="flex-1 text-slate-900">
                            <h4 className="m-0 leading-none font-semibold">Enterprise Tier <HddOutlined className="text-ecalmers-full" /></h4>
                        </div>
                        <div className="text-slate-400 text-xs">
                            Contact Us
                        </div>
                    </div>
                    <p className="leading-snug text-slate-600 text-sm text-wrap">
                        Unlimited vectors, Dedicated support, Custom integrations.
                    </p>
                </div>
            )
        },
    ];

    return(
        <div className="h-[60px] bg-[#1B191C] fixed top-0 left-0 right-0 opacity-100 md:opacity-80 z-20">
            <div className="w-full h-full px-4 md:px-8 flex items-center">
                <div className="flex-1">
                    <a href='/' target="_blank" className="hover:text-white focus:text-white" onClick={() => sendEvent({category:'Logo', action:'Click', label:'Home - Navbar Logo'})}>
                        <div className="flex gap-2 items-center">
                            <div className="text-2xl bg-black rounded-lg w-[40px] h-[40px] flex items-center justify-center text-[#1677ff]">
                                <SwapOutlined />
                            </div>
                            <h1 className="font-bold text-2xl leading-none m-0 no-mobile">
                                OnlyVectors
                            </h1>
                        </div>
                    </a>
                </div>
                <div className="flex gap-8 items-center">
                    <div className="flex gap-8 items-center no-mobile">
                        <Dropdown menu={{items:typeItems}} placement="bottomRight" className="no-mobile">
                            <div className="text-base cursor-pointer h-full border-b-2 transition duration-200 font-medium border-transparent hover:border-white">Types</div>
                        </Dropdown>
                        <Dropdown menu={{items:featureItems}} placement="bottomRight" className="no-mobile">
                            <div className="text-base cursor-pointer h-full border-b-2 transition duration-200 font-medium border-transparent hover:border-white">Features</div>
                        </Dropdown>
                        <Dropdown menu={{items:applicationItems}} placement="bottomRight" className="no-mobile">
                            <div className="text-base cursor-pointer h-full border-b-2 transition duration-200 font-medium border-transparent hover:border-white">Applications</div>
                        </Dropdown>
                        <Dropdown menu={{items:pricingItems}} placement="bottomRight" className="no-mobile">
                            <div className="text-base cursor-pointer h-full border-b-2 transition duration-200 font-medium border-transparent hover:border-white">Pricing</div>
                        </Dropdown>
                    </div>
                    <div className="flex gap-1 items-center">
                        {
                            user
                            ?   <Popover content={<div className="text-[#1B191C]">Go to Dashboard</div>} className="cursor-pointer" onClick={() => sendEvent({category:'Button', action:'Click', label:'Go to Dash'})}>
                                    <Link to='/dashboard/'>
                                        <Button type={window.innerWidth > 1000 ? "primary" : 'default'} size='medium' className="font-medium shadow-none rounded-lg md:rounded-3xl" >Go to Dashboard</Button>
                                    </Link>
                                </Popover> 
                            :   <Popover content={<div className="text-[#1B191C]">Login/Register</div>} className="cursor-pointer" onClick={() => sendEvent({category:'Button', action:'Click', label:'Login/Register'})}>
                                    <Link to='/login'>
                                        <Button type={window.innerWidth > 1000 ? "primary" : 'default'} size='medium' className="font-medium shadow-none rounded-lg md:rounded-3xl">Login/Register</Button>
                                    </Link>
                                </Popover>
                        }
                        <div className="visible md:hidden">
                            <Button className="rounded-lg" icon={<MenuOutlined/>} onClick={() => setIsMobileMenuDrawerVisible(true)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainNavbar;