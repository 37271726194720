import { Button, Checkbox, Drawer, Input, Popover } from "antd";
import { CloseOutlined, SwapOutlined } from "@ant-design/icons";
import { useMainContext } from "../Context/MainContext";
import { Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";

function MobileMenuDrawer({isMobileMenuDrawerVisible,onClose}) {
    const {openRegister} = useMainContext();
    const {user} = useAuth();

    return(
        <Drawer
            open={isMobileMenuDrawerVisible}
            onCancel={onClose}
            onClose={onClose}
            width={'100%'}
            height={'100%'} 
            placement="right"
            style={{'backgroundColor':'black',width:'100%'}}
            closable={false}
            destroyOnClose
            >
            <div className="w-full h-full flex flex-col gap-8 p-4">
                <div className="flex items-center pb-4">
                    <div className="flex-1">
                        <a href='/' target="_blank" className="hover:text-white focus:text-white">
                            <div className="flex gap-2 items-center">
                                <div className="text-2xl bg-black rounded-lg w-[40px] h-[40px] flex items-center justify-center text-[#1677ff]" style={{border:'1px solid rgba(255,255,255,0.1)'}}>
                                    <SwapOutlined />
                                </div>
                                <h1 className="font-bold text-2xl leading-none m-0">
                                    OnlyVectors
                                </h1>
                            </div>
                        </a>
                    </div>
                    <div onClick={onClose} className="text-white font-3xl">
                        <CloseOutlined/>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <Link to='/' className="w-full">
                        <div className="text-2xl cursor-pointer h-full border-b-2 transition duration-200 font-semibold border-transparent hover:border-white">
                            Home
                        </div>
                    </Link>
                    <Link to='/about' className="w-full">
                        <div className="text-2xl cursor-pointer h-full border-b-2 transition duration-200 font-semibold border-transparent hover:border-white">
                            About
                        </div>
                    </Link>
                    <Link to='/dashboard' className="w-full">
                        <div className="text-2xl cursor-pointer h-full border-b-2 transition duration-200 font-semibold border-transparent hover:border-white">
                            Dashboard
                        </div>
                    </Link>
                </div>
                {
                    user
                    ?   <Popover content={<div className="text-[#1B191C]">Go to Dashboard</div>} className="cursor-pointer">
                            <Link to='/dashboard/'>
                                <Button type={window.innerWidth > 1000 ? "primary" : 'default'} size='large' className="font-semibold shadow-none rounded-lg md:rounded-3xl w-full" >Go to Dashboard</Button>
                            </Link>
                        </Popover> 
                    :   <Popover content={<div className="text-[#1B191C]">Login/Register</div>} className="cursor-pointer">
                            <Link to='/login'>
                                <Button type={window.innerWidth > 1000 ? "primary" : 'default'} size='large' className="font-semibold shadow-none rounded-lg md:rounded-3xl w-full">Login/Register</Button>
                            </Link>
                        </Popover>
                }
            </div>
        </Drawer>
    )
}

export default MobileMenuDrawer;