import { Button } from "antd";
import MainNavbar from "../../components/Navbar/MainNavbar";
import { useMainContext } from "../../components/Context/MainContext";
import { useEventTracker } from "../../components/Context/EventTrackingContext";
import { MailOutlined, SwapOutlined } from "@ant-design/icons";

function AboutPage(){
    const {openRegister} = useMainContext();
    const {sendEvent} = useEventTracker();

    const clickOpenRegister = (value) => {
        openRegister();
        sendEvent({category:'Button', action:'Click', label:`Home - ${value}`});
    }

    function reveal_by_id(id) {
        var reveals = document.querySelectorAll(id);
    
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 0;
        
            if (elementTop < windowHeight - elementVisible ) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    function reveal(){
        reveal_by_id(".reveal");
        reveal_by_id(".reveal-top");
        reveal_by_id(".reveal-left");
        reveal_by_id(".reveal-right");
    }
    
    return(
        <div onScroll={reveal} className="w-screen h-screen bg-black text-white overflow-y-auto">
            <MainNavbar />
            <div className="h-full md:h-screen w-screen py-16 md:pt-[60px] flex justify-center">
                <div className="w-11/12 md:w-4/5 h-full md:h-4/5 m-auto p-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 flex items-center h-full pb-36">
                        <div className="text-base font-medium">
                            <h1 className="text-4xl font-bold leading-none md:leading-tight mb-5 reveal-left">
                                About Us
                            </h1>
                            <div className="reveal">
                                <p className="mb-4">
                                    We strongly believe in the power of building, launching, testing and iterating as fast as possible.
                                </p>
                                <p className="mb-4">
                                    OnlyVectors was created to help quickly build and manage a vector database + backend for new project ideas so you can launch your ideas faster.
                                </p>
                                <p className="mb-4">
                                    We want to make bringing your ideas to life as easy as creating a new Excel file.
                                </p>
                                <p className="mb-4">
                                    Try it out for free today!
                                </p>
                                <p className="mb-4">
                                    If you have any questions or feedback email me~
                                </p>
                                <p className="mb-5">
                                    -Daiki, Founder of OnlyVectors
                                </p>
                                <Button className="rounded-xl" href="mailto:me@daikiminaki.com" target="_blank" onClick={() => clickOpenRegister('Conteact')}>
                                    <MailOutlined/> Contact me
                                </Button>
                            </div>
                        </div>
                        <div className="h-full flex items-center justify-center">
                            <div className="text-5xl bg-black rounded-lg w-[120px] h-[120px] flex items-center justify-center text-[#1677ff]">
                                <SwapOutlined />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-auto md:h-screen w-screen py-10 md:pt-[60px]">
                <div className="w-11/12 md:w-4/5 h-full m-auto p-8 flex items-center justify-center">
                    <div className="text-center pb-32">
                        <h1 className="text-4xl font-bold leading-none md:leading-tight mb-5 reveal">
                            Just Try It.
                        </h1>
                        <div className="w-full h-1/2">
                            <div className="reveal">
                                <Button type="primary" size="large" className="font-semibold shadow-none rounded-xl" onClick={() => clickOpenRegister('CTA - Try It')} >Okay, I'll Try It</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;