import { createContext, useContext, useMemo } from "react";
import { ConfigProvider } from "antd";
import LoginModal from "../Modal/LoginModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { useAuth } from "../../utils/useAuth";
import MobileMenuDrawer from "../Drawer/MobileMenuDrawer";

const MainContext = createContext();

export const MainProvider = ({ children }) => {
    const {user} = useAuth();
    const [isLoginModalVisible,setIsLoginModalVisible] = useLocalStorage('loginToggle',false);
    const [isMobileMenuDrawerVisible,setIsMobileMenuDrawerVisible] = useLocalStorage('mobileMenuToggle',false);
    const [startTab,setStartTab] = useLocalStorage('registration',false);
    

    const openRegister = () => {
        if(user){
            window.open('/dashboard/');
        }
        else{
            setStartTab('registration');
            setIsLoginModalVisible(true);
        }
    }
    const openLogin = () => {
        if(user){
            window.open('/dashboard/');
        }
        else{
            setStartTab('login');
            setIsLoginModalVisible(true);
        }
    }

    const value = useMemo(
        () => ({
            isLoginModalVisible,setIsLoginModalVisible,
            isMobileMenuDrawerVisible,setIsMobileMenuDrawerVisible,
            startTab,setStartTab,
            openRegister, openLogin,
        }),[isLoginModalVisible,isMobileMenuDrawerVisible,startTab]
    );

    return(
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        contentBg:'#1B191C',
                    },
                    Table:{
                        rowSelectedHoverBg:'#1B191C',
                    },
                    Input:{
                        activeBorderColor:'white',
                        activeShadow:'0'
                    }
                },
                token: {
                    padding:0,
                    colorBgContainer:'#1B191C',
                    colorText:'white',
                    colorTextPlaceholder:'#595959',
                    colorBgContainerDisabled:'rgba(255,255,255,0.04)',
                    colorTextDisabled:'rgba(255,255,255,0.25)',
                },
            }}>
            <MainContext.Provider value={value}>
                {children}
                <LoginModal isLoginModalVisible={isLoginModalVisible} closeModal={() => setIsLoginModalVisible(false)} startTab={startTab} />
                <MobileMenuDrawer isMobileMenuDrawerVisible={isMobileMenuDrawerVisible} onClose={() => setIsMobileMenuDrawerVisible(false)} />
            </MainContext.Provider>
        </ConfigProvider>
    );
};

export const useMainContext = () => {
    return useContext(MainContext);
};